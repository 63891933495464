import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed');

class AccordionBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    node.innerHTML = `
      <div class="accordion info-accordion" id="${value.id}" contenteditable="false">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button id="titel-${value.id}" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#${value.collapseId}" aria-expanded="false" aria-controls="${value.collapseId}">
                ${value.titel}
            </button>
          </h2>
          <div id="${value.collapseId}" class="accordion-collapse" aria-labelledby="${value.headingId}" data-bs-parent="${value.id}">
            <div class="accordion-body" id="text-${value.id}">
                ${value.text}
            </div>
          </div>
        </div>
      </div>`;
    node.setAttribute('id', value.id);
    return node
  }

  static value(node) {
    if (node.querySelector('.accordion')) {
      return {
        id: node.querySelector('.accordion').id,
        headingId: node.querySelector('.accordion-header').id,
        collapseId: node.querySelector('.accordion-collapse').id,
        title: node.querySelector('.accordion-button').textContent,
        content: node.querySelector('.accordion-body').innerHTML,
      };
    }
  }

  deleteAt() {
    return false
  }
}

AccordionBlot.blotName = 'accordion';
AccordionBlot.tagName = 'div';
AccordionBlot.className = 'ql-accordion';

export default AccordionBlot;
