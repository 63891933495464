  import { Controller } from "@hotwired/stimulus"

  // Connects to data-controller="bild"
  export default class extends Controller {
    static targets = ["bildSelect", "bildPreview", "bildHidden"]

    setBild() {
      this.bildSelectTarget.click();
    }

    setBildPreview() {
      let bild = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.bildHiddenTarget.value = e.target.result;
        this.bildPreviewTarget.src = e.target.result;
      };
      reader.readAsDataURL(bild);
    }
  }
