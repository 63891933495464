import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed');

class frageBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    node.innerHTML = `<p>[Platzhalter für Frage: ${value.frage}]</p>`;
    node.innerHTML += `<p id="frage-id" hidden="true">${value.id}</p>`
    node.innerHTML += `<p id="frage-typ" hidden="true">${value.typ}</p>`
    return node;
  }
}

frageBlot.blotName = 'custom-frage';
frageBlot.tagName = 'div';
frageBlot.className = 'ql-custom-frage';

export default frageBlot;
