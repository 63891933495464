import Quill from 'quill';
import {Controller} from "@hotwired/stimulus";
import AccordionBlot from '../components/Lernen/accordionBlot';
import ImageBlot from "../components/Lernen/imageBlot";
import FrageBlot from "../components/Lernen/frageBlot";

Quill.register(AccordionBlot);
Quill.register(ImageBlot);
Quill.register(FrageBlot);

// Controller für Quill Text Editor
// Connects to data-controller="quill"
export default class extends Controller {
  static targets = ["de_input", "fr_input", "it_input", "bildmodal", "infomodal", "fragenmodal", "hiddenGeloeschteBilder", "hiddenContainer", "hiddenContainerModal"];
  selectedLine; // Selected Line für Editor de, fr und it
  selectedInfoLine; // Selected Line für Editor Instanz in Infomodal
  
  connect() {
    this.initializeQuill('de');
    
    // Event listener für Sprach-Tabs switch
    document.querySelectorAll('[data-bs-toggle="tab"]').forEach(tab => {
      tab.addEventListener('shown.bs.tab', this.reconnect.bind(this));
    });
  }

  disconnect() {
    if (this.quill) {
      const toolbarContainer = this.quill.getModule('toolbar').container;
      toolbarContainer.parentNode.removeChild(toolbarContainer);
      this.quill = null;
    }
  }
  
  reconnect() {
    let language = document.querySelector('[data-bs-toggle="tab"].active').dataset.language;
    this.disconnect();
    this.initializeQuill(language);
    this.resetSelectedLine();
    this.clearImageModal();
  }
  
  resetSelectedLine() {
   this.selectedLine = undefined; 
   this.selectedInfoLine = undefined; 
  }
  
  initializeQuill(param) {
    // hidden field Inputs pro sprache
    const inputTargets = {
      de: this.de_inputTarget,
      fr: this.fr_inputTarget,
      it: this.it_inputTarget,
    };
    
    let editor = document.getElementById(`editor-${param}`);
    this.quill = new Quill(editor, {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'color': ['#00283c', 'red', 'white', '#b94a48', '#5677bd'] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'align': [] }],
          ['blockquote', 'link'],
          ['video', 'bild-custom'],
          ['frage-custom', 'info-custom']
        ],
        uploader: {
          handler: (range) => {
            if (param === 'info') {
              this.selectedInfoLine = range.index;
            } else {
              this.selectedLine = range.index;
            }
          }
        },
      }
    });
    
    // Bilder Drag and Drop -> öffne Modal und zeige Previews
    editor.addEventListener('drop', (event) => {
      this.handleImagePasteActions(event.dataTransfer);
    });

    // Bilder Copy/Paste -> öffne Modal und zeige Previews
    editor.addEventListener('paste', (event) => {
      this.handleImagePasteActions(event.clipboardData);
    });

    if (inputTargets[param]) {
      this.quill.container.firstChild.innerHTML = inputTargets[param].value;
      this.quill.on('text-change', (data, oldData) => {
        // Editor Inhalt zu hidden field 
        inputTargets[param].value = this.quill.container.firstChild.innerHTML;
      });
    }

    // Custom Toolbar Erweiterungen
    this.setupCustomToolbarActions(param);
  }
  
  // Image Drop/Paste und Modal Previews setzen
  handleImagePasteActions(data) {
    const istBild = Array.from(data.files).map(f => f.type).some(type => type.includes('image'));
    if (istBild) {
      if (this.quill.container.id === 'editor-info') {
        // wenn Bildmodal von Infomodal geöffnet werden soll 
        const modal = bootstrap.Modal.getInstance(this.infomodalTarget);
        modal.hide();
      }
      const bildmodal = new bootstrap.Modal(this.bildmodalTarget);
      bildmodal._element.querySelector('#removeButton').hidden = false;
      if (data && data.files && data.files.length) {
        const files = Array.from(data.files);
        files.forEach((file) => {
          if (file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => {
              this.setImagePreview([e.target.result]);
            };
            reader.readAsDataURL(file);
          }
        });
      }
      bildmodal.show();
    }
  }

  setupCustomToolbarActions(param) {
    // Bild hinzufügen
    document.querySelectorAll('.ql-bild-custom').forEach(button => {
      button.innerHTML = '<i class="fa-solid fa-image"></i>';
      button.setAttribute('data-bs-target', '#bild-modal');
      button.setAttribute('data-bs-toggle', 'modal');
      button.setAttribute('type', 'button');
      button.addEventListener('click', () => {
        if (this.quill.getSelection()) {
          if (param === "info") {
            this.selectedInfoLine = this.quill.getSelection().index;
          } else {
            this.selectedInfoLine = undefined;
            this.selectedLine = this.quill.getSelection().index;
          }
        }
      });
    });

    if (param !== "info") { 
      // Frage hinzufügen
      document.querySelectorAll('.ql-frage-custom').forEach(button => {
        button.innerHTML = '<i class="fa-solid fa-question"></i>';
        button.setAttribute('data-bs-target', '#fragen-modal');
        button.setAttribute('data-bs-toggle', 'modal');
        button.setAttribute('type', 'button');
        button.addEventListener('click', () => {
          if (this.quill.getSelection()) {
            this.selectedLine = this.quill.getSelection().index;
          }
        });
      });

      // Info-Dropdown hinzufügen
      document.querySelectorAll('.ql-info-custom').forEach(button => {
        button.innerHTML = '<i class="fa-solid fa-info"></i>';
        button.setAttribute('data-bs-target', '#info-modal');
        button.setAttribute('data-bs-toggle', 'modal');
        button.setAttribute('type', 'button');
        button.addEventListener('click', () => {
          if (this.quill.getSelection()) {
            this.selectedLine = this.quill.getSelection().index;
          }
          this.disconnect();
          this.initializeQuill('info');
        });
      });
    }
  }
  
  // Click-Events auf Element im Editor
  clickEvent() {
    this.quill.focus();
    let target = event.target;
    if (target.className.includes('accordion')) {
      let accordionId = target.closest('.info-accordion').id;
      this.disconnect();
      this.initializeQuill('info');
      let infoContainer = Quill.find(target.closest('.ql-accordion'));
      this.selectedLine = infoContainer.offset(this.quill.scroll);
      let titel = document.getElementById(`titel-${target.id.split('-')[1]}`).textContent.trim();
      let text =  document.getElementById(`text-${target.id.split('-')[1]}`).innerHTML.trim();
      document.getElementById("info-titel").value = titel;
      this.quill.container.firstChild.innerHTML = text;
      document.getElementById("accordion-id-hidden").value = accordionId;
      
      const infomodal = new bootstrap.Modal(this.infomodalTarget);
      infomodal._element.querySelector('#removeButton').hidden = false;
      infomodal.show();
    } else if (target.tagName === 'IMG') {
      let imageContainer = Quill.find(target.parentElement);
      if (imageContainer) {
        if (this.quill.container.id === "editor-info") {
          this.selectedInfoLine = imageContainer.offset(this.quill.scroll);
        } else {
          this.selectedInfoLine = undefined;
          this.selectedLine = imageContainer.offset(this.quill.scroll);
        }
        // images zu preview zuweisen
        let images = imageContainer.domNode.querySelectorAll("img");
        let sources = Array.from(images, img => img.src);
        let ids = Array.from(images, img => img.id);
        this.setImagePreview(sources, ids);

        let setWidth = document.getElementById("set-width");
        setWidth.value = Math.ceil((target.offsetWidth / event.target.parentElement.offsetWidth * 100) * images.length);

        if (this.quill.container.id === 'editor-info') {
          // wenn Bildmodal von Infomodal geöffnet werden soll 
          const modal = bootstrap.Modal.getInstance(this.infomodalTarget);
          modal.hide();
        }
        const bildmodal = new bootstrap.Modal(this.bildmodalTarget);
        bildmodal._element.querySelector('#removeButton').hidden = false;

        bildmodal.show();
      }
    }
  }
  addInfoModalData() {
    let cursorPosition = this.selectedLine ? this.selectedLine : this.quill.scroll.length();
    let idSuffix = Date.now();
    let value = {
      id: `${idSuffix}`,
      headingId: `heading-${idSuffix}`,
      collapseId: `collapse-${idSuffix}`,
      titel: document.getElementById("info-titel").value,
      text: this.quill.container.firstChild.innerHTML
    };
    this.removeAccordionBlot();
    this.quill.insertEmbed(cursorPosition, 'accordion', value);
    this.quill.insertEmbed(cursorPosition + 1, 'text', '');
  }
  
  addFragenModalData() {
    let cursorPosition = this.selectedLine ? this.selectedLine : this.quill.scroll.length();
    let value = {
      id: event.target.parentElement.querySelector("#frage-id").textContent,
      typ: event.target.parentElement.querySelector("#frage-typ").textContent,
      frage: event.target.parentElement.querySelector("#frage-text").textContent,
    };
    this.quill.insertEmbed(cursorPosition, 'custom-frage', value);
    this.quill.insertEmbed(cursorPosition + 1, 'text', '');
  }

  setImagePreview(imageSources = [], ids = null) {
    const previewContainer = document.getElementById("img-preview-container");
    const anzahlBilder = previewContainer.childElementCount;
    Array.from(document.getElementsByClassName('img-container')).forEach(container => container.style.width = `${100/(anzahlBilder + imageSources.length)}%`);

    if (imageSources.length > 0) {
      let width = `${100/(anzahlBilder + imageSources.length)}%`;
      imageSources.forEach((src, i) => {
        // Bild container mit Buttons
        let container = document.createElement("div");
        container.style.width = width;
        container.className = "img-container";
        const deleteButton = document.createElement('div');
        const leftButton = document.createElement('div');
        const rightButton = document.createElement('div');
        deleteButton.innerHTML = '<div class="img-button delete" data-action="click->quill#previewAction"><i class="fa-solid fa-trash fa-2xl" style="color: #5677bd;"></i></div>';
        leftButton.innerHTML = '<div class="img-button left" data-action="click->quill#previewAction"><i class="fa-solid fa-arrow-left fa-2xl" style="color: #5677bd;"></i></div>';
        rightButton.innerHTML = '<div class="img-button right" data-action="click->quill#previewAction"><i class="fa-solid fa-arrow-right fa-2xl" style="color: #5677bd;"></i></div>';

        // Bild
        let img = document.createElement("img");
        if (ids && ids.length > 0) {
          img.id = ids[i];
        }
        img.src = src;
        img.className = "img-preview";
        img.style.border = "black 1px dotted";
        img.style.width = '100%';

        container.appendChild(img);
        container.appendChild(deleteButton);
        container.appendChild(leftButton);
        container.appendChild(rightButton);
        previewContainer.appendChild(container);
      });
    }
  }
  
  previewAction() {
    let buttonClass= event.currentTarget.className;
    switch (buttonClass) {
      case 'img-button delete':
        let imageContainer = event.currentTarget.parentElement.parentElement;
        imageContainer.remove();
        this.setBildHidden(this.hiddenContainerModalTarget,[imageContainer.childNodes[0]])
        this.setImagePreview();
        break;
      default:
        let imagesArray = Array.from(document.getElementsByClassName('img-container'));
        let selectedImage = event.currentTarget.parentElement.parentElement;
        let imageIndex = imagesArray.indexOf(selectedImage);
        let spliceStart = buttonClass === 'img-button left' ? imageIndex - 1 : imageIndex + 1;
        if (imageIndex !== -1 && spliceStart !== -1) {
          // image von entfernen  
          const element = imagesArray.splice(imageIndex, 1)[0];
          // image an neuer postition einsetzen
          imagesArray.splice(spliceStart, 0, element);
          let imageSources = imagesArray.map(img => img.getElementsByTagName('img')[0].src);
          Array.from(document.getElementsByClassName('img-container')).forEach(img => img.remove());
          this.setImagePreview(imageSources);
        }
        break;
    }
  }

  // Zugeschnittenes Bild zu Modal hinzufügen
  addBildCropModalData() {
    let croppedImageSrc = document.getElementById('image-data').value;
    this.setImagePreview([croppedImageSrc]);
  }

  // Bilder von Modal zu Editor hinzufügen
  addBildModalData() {
    let cursorPosition;
    if (this.selectedInfoLine !== undefined) {
      cursorPosition = this.selectedInfoLine;
    } else {
      cursorPosition = this.selectedLine ? this.selectedLine : this.quill.scroll.length();
    }
    let sources = [];
    let ids = [];
    let width = parseInt(document.getElementById("set-width").value);
    let idSuffix = Date.now();
    let images = document.getElementById("img-preview-container").children;

    for (let img of images) {
      ids.push(`custom-image-${idSuffix}`);
      sources.push(img.getElementsByTagName('img')[0].src);
    }
    let value = {
      ids: ids,
      width: width > 100 ? 100 / ids.length : width / ids.length,
      sources: sources
    };
    
    if (this.quill.container.id === 'editor-info') {
      const modal = new bootstrap.Modal(this.infomodalTarget);
      modal.show();
    }
    
    let geloeschteBilder = this.hiddenContainerModalTarget.childNodes;
    if (geloeschteBilder.length > 0) {
      geloeschteBilder.forEach((node) => {
        this.hiddenContainerTarget.appendChild(node)
      });
    }
    this.removeBildBlot(true);
    this.quill.insertEmbed(cursorPosition, 'custom-image', value);
    this.quill.insertEmbed(cursorPosition + 1, 'text', ''); 
    this.clearImageModal();
  }

  removeAccordionBlot() {
    let accordionId = document.getElementById("accordion-id-hidden").value
    this.clearInfoModal();
    let cursorPosition = this.selectedLine ? this.selectedLine : this.quill.scroll.length();
    let line = this.quill.getLine(cursorPosition);
    // Lösche vorheriges Info-Element
    if (line[0] && line[0].domNode.classList.contains("ql-accordion")) {
      const accordion = document.getElementById(line[0].domNode.id);
      accordion.remove();
    } else {
      // Absicherung wenn line nicht erkannt wird
      let alleInfoBlocks = this.quill.container.querySelectorAll('.ql-accordion');
      if (alleInfoBlocks) {
        alleInfoBlocks.forEach((block) => {
          if (block.id === accordionId) {
            block.remove();
          }
        });
      }

    }
  }
  
  removeBildBlot(skipSetHidden = false) {
    let cursorPosition = this.selectedInfoLine !== undefined ? this.selectedInfoLine : this.selectedLine;
    let line = this.quill.getLine(cursorPosition);
    // Lösche vorheriges Bild-Element
    if (line[0] && line[0].domNode.classList.contains("ql-custom-image")) {
      if (skipSetHidden !== true) {
        let bilder = line[0].domNode.childNodes;
        this.setBildHidden(this.hiddenContainerTarget, bilder);
      }
      line[0].domNode.remove();
    }
    this.clearImageModal();
  }
  
  clearImageModal() {
    const bildmodal = new bootstrap.Modal(this.bildmodalTarget);
    bildmodal._element.querySelector('#removeButton').hidden = true;
    Array.from(document.getElementsByClassName('img-container')).forEach(img => img.remove());
    if (this.quill.container.id === 'editor-info') {
      const modal = new bootstrap.Modal(this.infomodalTarget);
      modal.show();
    }
    document.getElementById('set-width').value = 50;
  }

  clearInfoModal() {
    const infomodal = new bootstrap.Modal(this.infomodalTarget);
    infomodal._element.querySelector('#removeButton').hidden = true;
    document.getElementById('info-titel').value = '';
    this.quill.setContents('');
    this.reconnect();
    $('body').removeClass('modal-open');
    document.querySelectorAll('.modal-backdrop').forEach(element => {
      element.remove();
    });
  }

  setBildHidden(containerTarget, bilder) {
    bilder.forEach(bild => {
      if (bild.id && !bild.src.includes('base64')) {
        let clonedHidden = this.hiddenGeloeschteBilderTarget.cloneNode(true);
        clonedHidden.removeAttribute('data-quill-target');
        clonedHidden.value = bild.id;
        containerTarget.appendChild(clonedHidden)
      }
    })
  }
}
